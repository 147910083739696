import React from "react";
import { Link } from "react-scroll";
import { useAuth } from '../Auth';

export const Navigation = (props) => {
  const { user, logout } = useAuth(); 
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
            B5 DATA NEXUS
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/camelina" className="page-scroll">
                Camelina
              </a>
            </li>
            <li>
              <a href="/pennycress" className="page-scroll">
                Pennycress
              </a>
            </li>
            <li>
              <a href="/cuphea" className="page-scroll">
                Cuphea
              </a>
            </li>
            {/* <li>
              <a href="/#about" className="page-scroll">
                About
              </a>
            </li> */}
            <li>
              <a href="/#team" className="page-scroll">
                Team
              </a>
            </li>
            {/* <li>
              <a href="/#contact" className="page-scroll">
                Contact
              </a>
            </li> */}
            {user ? (
                <>
                  <li>
                    <a href="#" className="page-scroll">
                      Hello! Admin {/* Display the user's first name */}
                    </a>
                  </li>
                  <li>
                    <a href="#" className="page-scroll" onClick={logout}>
                      Log out
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a href="/signin" className="page-scroll">
                      Sign In
                    </a>
                  </li>
                  
                </>
              )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
