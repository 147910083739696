import React, { useState } from "react";
import { useAuth } from '../Auth';

const SignIn = () => {
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const onSubmit = (e) => {
    e.preventDefault();
    handleLogin(password); 
  };

  const handleLogin = (password) => {
    if (password === 'B5@doedata') {
      const userData = { username: 'admin' }; // Create user data object
      login(userData); 
      window.location.href = '/'; 
    } else {
      alert("Invalid credentials! Please try again.");
    }
  };
  

  return (
    <div id="organisms" className="text-center">
      <div className="container">
        <div className="section-title">
          <form onSubmit={onSubmit}>
            <h2>Sign In</h2>

            {/* Password input */}
            <div className="col-md-8 col-md-offset-2 intro-text" style={{ marginBottom: "20px" }}>
              <input
                type="password"
                className="form-control"
                placeholder="Enter admin password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: "50%", margin: "0 auto" }}
              />
            </div>

            {/* Submit button */}
            <div className="col-md-8 col-md-offset-2 intro-text">
              <button type="submit" className="btn btn-custom btn-lg page-scroll">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
