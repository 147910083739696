import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './Auth'; // Adjust the import path as necessary

const ProtectedRoute = ({ element }) => {
  const { user } = useAuth(); // Get the user from AuthContext

  if (!user) {
    alert("Please log in first!");
    return <Navigate to="/signin" replace />;
  }

  return element;
};

export default ProtectedRoute;