import React, { useState } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa"; // Import Chevron icons

export const Camelina = (props) => {
  const [showTranscriptomics, setShowTranscriptomics] = useState(true);
  const [showProteomics, setShowProteomics] = useState(false);
  const [showMetabolomics, setShowMetabolomics] = useState(false);
  const [showTools, setShowTools] = useState(false);
  const [showC10, setShowC10] = useState(false);  
  const [showWT, setShowWT] = useState(false); 
  const [showC10_vs_WT, setShowC10_vs_WT] = useState(false); 

  return (
    <div id="organisms" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Camelina</h2>
        </div>

        {/* Transcriptomics Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowTranscriptomics(!showTranscriptomics)}
            style={{ cursor: "pointer" }}
          >
            Transcriptomics{" "}
            {showTranscriptomics ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
          <p style={{ fontStyle: 'italic' }}>Masterfile contains analyzed transcriptomics data, including gene identifiers (gene names, assembled sequence # etc.), gene expression (FPKM), differential expression results (pvalue, qvalue, log2 fold change), reference genome functional annotations, pathways, domains etc. information for same organism and Arabidopsis homologs, functional annotations, pathways etc.</p>
        <h5 style={{ marginTop: "30px" }}>All the below files are Developmental Stages</h5>
        </div>
        {showTranscriptomics && (
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-xs-6 col-md-3" style={{ marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>C10 (vs C10)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Camelina/master_camelina_c10.xlsx" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowC10(!showC10)}
              style={{ cursor: "pointer" }}
            >
              C10_Comparisons{" "}
              {showC10 ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showC10 && (
              <>            
            <p><a href="/files/Camelina/C10_Conditions/C10_15_vs_C10_10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
              C10_15_vs_C10_10
            </a></p>
            <p><a href="/files/Camelina/C10_Conditions/C10_20_vs_C10_10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_20_vs_C10_10
            </a></p>
            <p><a href="/files/Camelina/C10_Conditions/C10_25_vs_C10_10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_25_vs_C10_10
            </a></p>
            <p><a href="/files/Camelina/C10_Conditions/C10_30_vs_C10_10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_30_vs_C10_10
            </a></p>
            <p><a href="/files/Camelina/C10_Conditions/C10_35_vs_C10_10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_35_vs_C10_10
            </a></p>
            <p><a href="/files/Camelina/C10_Conditions/C10_MAT_vs_C10_10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_MAT_vs_C10_10
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6 col-md-3" style={{ marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>WT (vs WT10)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Camelina/master_camelina_wt.xlsx" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowWT(!showWT)}
              style={{ cursor: "pointer" }}
            >
              WT_Comparisons{" "}
              {showWT ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showWT && (
              <>    
            <p><a href="/files/Camelina/WT_Conditions/results_WT15_vs_WT10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT15_vs_WT10
            </a></p>
            <p><a href="/files/Camelina/WT_Conditions/results_WT20_vs_WT10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT20_vs_WT10
            </a></p>
            <p><a href="/files/Camelina/WT_Conditions/results_WT25_vs_WT10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT25_vs_WT10
            </a></p>
            <p><a href="/files/Camelina/WT_Conditions/results_WT30_vs_WT10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT30_vs_WT10
            </a></p>
            <p><a href="/files/Camelina/WT_Conditions/results_WT35_vs_WT10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT35_vs_WT10
            </a></p>
            <p><a href="/files/Camelina/WT_Conditions/results_WTMAT_vs_WT10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WTMAT_vs_WT10
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6 col-md-3" style={{ marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>C10 vs WT</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Camelina/master_camelina_c10_vs_wt.xlsx" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowC10_vs_WT(!showC10_vs_WT)}
              style={{ cursor: "pointer" }}
            >
              C10_vs_WT_Comparisons{" "}
              {showC10_vs_WT ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showC10_vs_WT && (
              <>            
            <p><a href="/files/Camelina/C10_vs_WT_Conditions/C10_10_vs_WT10.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_10_vs_WT10
            </a></p>
            <p><a href="/files/Camelina/C10_vs_WT_Conditions/C10_15_vs_WT15.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_15_vs_WT15
            </a></p>
            <p><a href="/files/Camelina/C10_vs_WT_Conditions/C10_20_vs_WT20.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_20_vs_WT20
            </a></p>
            <p><a href="/files/Camelina/C10_vs_WT_Conditions/C10_25_vs_WT25.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_25_vs_WT25
            </a></p>
            <p><a href="/files/Camelina/C10_vs_WT_Conditions/C10_30_vs_WT30.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_30_vs_WT30
            </a></p>
            <p><a href="/files/Camelina/C10_vs_WT_Conditions/C10_35_vs_WT35.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_35_vs_WT35
            </a></p>
            <p><a href="/files/Camelina/C10_vs_WT_Conditions/C10_MAT_vs_WTMAT.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            C10_MAT_vs_WTMAT
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6 col-md-3" style={{ marginBottom: "20px" }}>
            <b style={{ fontSize: "18px"}}>Engineered Cuphea Genes</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Camelina/C10 camelina_4Cuphea.xlsx" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            </div>
          </div>
        )}

        {/* Proteomics Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowProteomics(!showProteomics)}
            style={{ cursor: "pointer" }}
          >
            Proteomics{" "}
            {showProteomics ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        {showProteomics && (
          <div className="row">
            <p style={{ fontStyle: 'italic' }}>Masterfile contains analyzed proteomics data, including gene identifiers (gene names), gene expression (FPKM) and differential expression results (pvalue, qvalue, log2 fold change, centered values).</p>
            <div className="col-xs-18 col-md-12" style={{ marginBottom: "20px" }}>
              {/* <b style={{ fontSize: "18px"}}>Proteomics Master File</b> */}
              <b style={{ fontSize: "18px"}}><a href="/files/Camelina/Camelina_Proteomics.xlsx" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontWeight: "bold" }}>
              Proteomics Master File
            </a></b>
            {/* <p><a href="https://kbcommons.org/system/browse/diff_exp/CamelinaSativa" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
                3D Omics
            </a></p> */}
            </div>
          </div>
        )}

        {/* Metabolomics Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowMetabolomics(!showMetabolomics)}
            style={{ cursor: "pointer" }}
          >
            Metabolomics{" "}
            {showMetabolomics ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        {showMetabolomics && (
          <div className="row">
            <p style={{ fontStyle: 'italic' }}>Masterfile contains analyzed metabolomics data, including Compound Name, Formula, Average and Standard Deviation of abundance levels for all replicates.</p>
            <div className="col-xs-18 col-md-12" style={{ marginBottom: "20px" }}>
              {/* <b style={{ fontSize: "18px"}}>Lipidomics Master File</b> */}
              <b style={{ fontSize: "18px"}}><a
                href="/files/Camelina/Camelina_Lipidomics.xlsx"
                download
                style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontWeight: "bold"  }}
                >
                Lipidomics Master File
            </a></b>
            </div>
          </div>
        )}

        {/* Tools Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowTools(!showTools)}
            style={{ cursor: "pointer" }}
          >
            Tools{" "}
            {showTools ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        {showTools && (
          <div className="row">
            <div className="col-xs-8 col-md-3" style={{ marginBottom: "20px" }}>
            <p><a href="https://kbcommons.org/system/home/CamelinaSativa" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}>
                KBCommons
            </a></p>
            </div>
            <div className="col-xs-8 col-md-3" style={{ marginBottom: "20px" }}>
            <p><a href="https://kbcommons.org/system/browse/diff_exp/CamelinaSativa" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}>
                3D Omics
            </a></p>
            </div>
            <div className="col-xs-8 col-md-3" style={{ marginBottom: "20px" }}>
            <p><a href="https://kbcommons.org/system/browse/ccmt" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}>
                CCMT
            </a></p>
            </div>
            <div className="col-xs-8 col-md-3" style={{ marginBottom: "20px" }}>
            <p><a href="https://ccmt.missouri.edu" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}>
                Gene Explorer
            </a></p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
