import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser ] = useState(() => {
    const savedUser  = localStorage.getItem('user');
    try {
      return savedUser  ? JSON.parse(savedUser ) : null;
    } catch (error) {
      console.error("Error parsing user data from localStorage:", error);
      return null; // Return null if parsing fails
    }
  });

  const login = (userData) => {
    setUser (userData);
    localStorage.setItem('user', JSON.stringify(userData)); // Store as JSON
  };

  const logout = () => {
    setUser (null);
    localStorage.removeItem('user');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);