import React, { useState } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa"; // Import Chevron icons

export const Cuphea = (props) => {
  const [showIsoSeq, setShowIsoSeq] = useState(false);
  const [showTranscriptomics, setShowTranscriptomics] = useState(true);
  const [showProteomics, setShowProteomics] = useState(false);
  const [showMetabolomics, setShowMetabolomics] = useState(false);
  const [showTools, setShowTools] = useState(false);
  const [showConditions, setShowConditions] = useState(false);

  return (
    <div id="organisms" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Cuphea</h2>
        </div>

        {/* IsoSeq Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowIsoSeq(!showIsoSeq)}
            style={{ cursor: "pointer" }}
          >
            PacBio IsoSeq{" "}
            {showIsoSeq ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        {showIsoSeq && (
          <div className="row">
            <p style={{ fontStyle: 'italic' }}>Assembled sequences annotated with reference genome functional annotations, pathways, domains etc. information for same organism and Arabidopsis homologs, functional annotations, pathways etc.</p>
            
            <div className="col-xs-18 col-md-12" style={{ marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>Pabcio Hifi IsoSeq</b>
              <p><a href="/files/Cuphea/Cuphea_Hifi_IsoSeq.fasta" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
                Fasta file
            </a></p>
            <p><a href="/files/Cuphea/Cuphea_Hifi_IsoSeq.gff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
                GFF file
            </a></p>
            <p><a href="/files/Cuphea/Cuphea_annotated_assembled_sequences.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
                Annotated Assembled Sequences
            </a></p>
            </div>
          </div>
        )}

        {/* Transcriptomics Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowTranscriptomics(!showTranscriptomics)}
            style={{ cursor: "pointer" }}
          >
            Transcriptomics{" "}
            {showTranscriptomics ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
          <p style={{ fontStyle: 'italic' }}>Masterfile contains analyzed transcriptomics data, including gene identifiers (gene names, assembled sequence # etc.), gene expression (FPKM), differential expression results (pvalue, qvalue, log2 fold change), reference genome functional annotations, pathways, domains etc. information for same organism and Arabidopsis homologs, functional annotations, pathways etc. </p>
        <h5 style={{ marginTop: "30px" }}>All the below files are Developmental Stages</h5>
        </div>
        {showTranscriptomics && (
          <div className="row"  style={{ marginTop: "20px" }}>
            <div className="col-xs-18 col-md-6" style={{ marginBottom: "20px" }}>
            <b style={{ fontSize: "18px"}}>PacBio Hifi IsoSeq (vs CV6DAF)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Cuphea/Cuphea_with_Hifi_Isoseq_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            {/* Conditions Section */}
            {/* <p
              onClick={() => setShowConditions(!showConditions)}
              style={{ cursor: "pointer" }}
            >
              CV6DAF Comparisons{" "}
              {showConditions ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p> */}
            {showConditions && (
              <>
            <p><a href="/files/Cuphea/CV10DAF_vs_CV6DAF/CV10DAF_vs_CV6DAF.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            CV10DAF_vs_CV6DAF
            </a></p>
            <p><a href="/files/Cuphea/CV14DAF_vs_CV6DAF/CV14DAF_vs_CV6DAF.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            CV14DAF_vs_CV6DAF
            </a></p>
            <p><a href="/files/Cuphea/CV18DAF_vs_CV6DAF/CV18DAF_vs_CV6DAF.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            CV18DAF_vs_CV6DAF
            </a></p>
            
            </>
            )}
            </div>

            <div className="col-xs-18 col-md-6" style={{ marginBottom: "20px" }}>
            <b style={{ fontSize: "18px"}}>Trinity De Novo(vs CV6DAF)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Cuphea/Cuphea_with_Trinity_Master_File.xlsx" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            {/* Conditions Section */}
            {/* <p
              onClick={() => setShowConditions(!showConditions)}
              style={{ cursor: "pointer" }}
            >
              CV6DAF Comparisons{" "}
              {showConditions ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showConditions && (
              <>
            <p><a href="/files/Cuphea/CV10DAF_vs_CV6DAF/CV10DAF_vs_CV6DAF.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            CV10DAF_vs_CV6DAF
            </a></p>
            <p><a href="/files/Cuphea/CV14DAF_vs_CV6DAF/CV14DAF_vs_CV6DAF.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            CV14DAF_vs_CV6DAF
            </a></p>
            <p><a href="/files/Cuphea/CV18DAF_vs_CV6DAF/CV18DAF_vs_CV6DAF.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            CV18DAF_vs_CV6DAF
            </a></p>
            
            </>
            )} */}
            </div>
          </div>
        )}

        {/* Proteomics Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowProteomics(!showProteomics)}
            style={{ cursor: "pointer" }}
          >
            Proteomics{" "}
            {showProteomics ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        {showProteomics && (
          <div className="row">
            <p style={{ fontStyle: 'italic' }}>Masterfile contains analyzed proteomics data, including Protein Groups and expression values for each of the 4 replicates for all the 5 timepoints on comparison(CV6DAF).</p>
            <div className="col-xs-18 col-md-12" style={{ marginBottom: "20px" }}>
            {/* <b style={{ fontSize: "18px"}}>Proteomics Master File</b> */}
              <b style={{ fontSize: "18px"}}><a href="/files/Cuphea/Cuphea_proteomics.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontWeight: "bold" }}>
              Proteomics Master File
            </a></b>
            </div>
          </div>
        )}

        {/* Metabolomics Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowMetabolomics(!showMetabolomics)}
            style={{ cursor: "pointer" }}
          >
            Metabolomics{" "}
            {showMetabolomics ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        {showMetabolomics && (
          <div className="row">
            <div className="col-xs-18 col-md-12" style={{ marginBottom: "20px" }}>
              <p>No Data</p>
            </div>
          </div>
        )}

        {/* Tools Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowTools(!showTools)}
            style={{ cursor: "pointer" }}
          >
            Tools{" "}
            {showTools ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        {showTools && (
          <div className="row">
            <p style={{ fontStyle: 'italic' }}>Coming Soon...</p>
            <div className="col-xs-8 col-md-4" style={{ marginBottom: "20px" }}>
            <p>
              {/* <a href="https://kbcommons.org/system/home/Tarvense" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}> */}
                KBCommons
            {/* </a> */}
            </p>
            </div>
            <div className="col-xs-8 col-md-4" style={{ marginBottom: "20px" }}>
            <p>
              {/* <a href="https://kbcommons.org/system/browse/diff_exp/Cuphea" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold"  }}> */}
                3D Omics
            {/* </a> */}
            </p>
            </div>
            <div className="col-xs-8 col-md-4" style={{ marginBottom: "20px" }}>
            <p>
              {/* <a href="https://kbcommons.org/system/browse/ccmt" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold"  }}> */}
                CCMT
            {/* </a> */}
            </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
