import React from "react";
import { useNavigate } from "react-router-dom";

export const Organisms = (props) => {
  const navigate = useNavigate();

  const handleNavigation = (name) => {
    // Forward to a route based on the organism name (e.g., /organism/camleina)
    window.scrollTo(0, 0);
    navigate(`/${name.toLowerCase()}`);
  };

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Organisms</h2>
          <p>
            Data is available for below organisms.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4" onClick={() => handleNavigation(d.name)}
                style={{ cursor: "pointer" }} >
           
                  <img 
                  src={d.img} 
                  alt={d.name} 
                  style={{ width: "200px", height: "200px", objectFit: "cover" }} 
                />
                  {/* <i className={d.icon}></i> */}
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
