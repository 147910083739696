import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation.js";
import { Header } from "./components/header.js";
import { Organisms } from "./components/organisms.js";
import { Team } from "./components/Team.js";
import { Contact } from "./components/contact.js";
import { Camelina } from "./components/Camelina.js";
import { Pennycress } from "./components/Pennycress.js";
import { Cuphea } from "./components/Cuphea.js";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import SignIn from "./components/SignIn.js";
import { AuthProvider } from './Auth';
import ProtectedRoute from './ProtectedRoute';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <AuthProvider>
      <Navigation />
      <Routes>
        <Route 
          path="/" 
          element={
            <>
              <Header data={landingPageData.Header} />         
              <Organisms data={landingPageData.Organisms} />
              <Team data={landingPageData.Team} />
            </>
          } 
        />
        <Route path="/camelina" element={<ProtectedRoute element={<Camelina />} />} />
        <Route path="/pennycress" element={<ProtectedRoute element={<Pennycress />} />} />
          <Route path="/cuphea" element={<ProtectedRoute element={<Cuphea />} />} />
        <Route path="/signin" element={<SignIn />} />
      </Routes>
      <Contact data={landingPageData.Contact} />
      </AuthProvider>
    </Router>
  );
};

export default App;